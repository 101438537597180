import { useEffect, useState } from 'react';
import './App.css';
import Battlefield from './Battlefield';
import { PlayerData } from './Player';
import {v4 as uuid} from "uuid";

const LOCAL_STORAGE_KEY = "lifeswing.players";

function loadData(): PlayerData[] {
  let storedState: PlayerData[] = [];

  const rawStoredState = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (rawStoredState) {
    storedState = JSON.parse(rawStoredState);
  } else {
    for (let i = 0; i < 4; ++i) {
      storedState[i] = {
        id: uuid(),
        name: "Player " + (i + 1),
        life: 40,
        poison: 0,
      }
    }
  }

  return storedState;
}

function App() {

  let storedState = loadData();

  const [players, setPlayers] = useState<PlayerData[]>(storedState);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(players))
  }, [players]);

  function modifyLife(id: string, delta: number): number {
    const s = [...players];
    const player = s.find(s => s.id === id);
    if (player) {
      player.life += delta;
      setPlayers(s);
      return player.life;
    }
    return 0;
  }

  function modifyPoison(id: string, delta: number): number {
    const s = [...players];
    const player = s.find(s => s.id === id);
    if (player) {
      player.poison += delta;
      setPlayers(s);
      return player.poison;
    }
    return 0;
  }

  

  return (
    <Battlefield players={players} actions={{ modifyLife, modifyPoison }} />
  );
}

export default App;
