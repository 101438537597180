import "./Counter.css";

export default function Counter(props: { value: number, onMinus: () => number, onPlus: () => number, style?: React.CSSProperties }) {

    return (
        <div className="counter" style={props.style}>
            <span className="control" onClick={props.onMinus}>-</span>
            <span className="value">{props.value}</span>
            <span className="control" onClick={props.onPlus}>+</span>
        </div>
    );
}
