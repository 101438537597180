import "./Player.css";

import Counter from "./Counter";
import ScrollContainer from "./ScrollContainer";
import Background from "./Background";
import Rotator from "./Rotator";

export type PlayerData = {
    id: string,
    name: string,
    life: number,
    poison: number,
}

export type PlayerActions = {
    modifyLife: (id: string, delta: number) => number;
    modifyPoison: (id: string, delta: number) => number;
}

export default function Player(props: { data: PlayerData, actions: PlayerActions, rotationInDegree: number }) {
       return (
        <Rotator degree={props.rotationInDegree}>
            <div className="player">
                <span className="name">{props.data.name}</span>
                <ScrollContainer>
                    <Counter value={props.data.life} onMinus={() => { return props.actions.modifyLife(props.data.id, -1) }} onPlus={() => { return props.actions.modifyLife(props.data.id, +1) }} />
                    <Counter style={{color:"#77cc00"}} value={props.data.poison} onMinus={() => { return props.actions.modifyPoison(props.data.id, -1) }} onPlus={() => { return props.actions.modifyPoison(props.data.id, +1) }} />
                </ScrollContainer>
                <Background scryfallSearch={undefined} />
            </div>
        </Rotator>
        
    );
}