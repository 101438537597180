import "./Battlefield.css";

import Player, { PlayerActions, PlayerData } from "./Player";

export default function Battlefield(props: { players: PlayerData[], actions: PlayerActions }) {
    return (
        <div id="battlefield">
            {props.players.map((p: PlayerData, index: number) => {
                //key should be unique to allow react to rerender cleverly
                return <Player key={p.id} data={p} actions={props.actions} rotationInDegree={index % 2 === 0 ? 90 : -90} />
            })}
        </div>
    );
}