import { useEffect, useState } from "react"

import "./Background.css"

type loadingState = "unloaded" | "pending" | "loaded"

export default function Player(props: { scryfallSearch: string | undefined }) {

    const [state, setState] = useState({ imgUrl: "", artist: "Name", card: "Card" });
    const [loading, setLoading] = useState<loadingState>("unloaded");

    useEffect(() => {

        const request = props.scryfallSearch ? "https://api.scryfall.com/cards/random?q=-is:digital+-is:token+" + props.scryfallSearch : "https://api.scryfall.com/cards/random";

        setLoading("pending");

        fetch(request).then(response => {
            return response.json()
        }).catch(e => {
            setLoading("unloaded");
        }).then(res => {

            const data = props.scryfallSearch ? res.data[0] : res;

            let imgUrl;
            let artistName = data.artist;
            let cardName = data.name;

            if (!data.image_uris) {
                imgUrl = data.card_faces[0].image_uris.art_crop;
            } else {
                if (!data.image_uris || !data.image_uris.art_crop) {
                    console.error(data);
                    debugger;
                }
                imgUrl = data.image_uris.art_crop;
            }

            fetch(imgUrl).then(response => response.blob())
                .then(imageBlob => {
                    const imageObjectURL = URL.createObjectURL(imageBlob);
                    setState({ imgUrl: imageObjectURL, artist: artistName, card: cardName });
                    setLoading("loaded");
                });
        }).catch(e => {
            setLoading("unloaded");
        });
    }, [props.scryfallSearch]);

    return <div className="background">
        <div className="backgroundImage" style={{ backgroundImage: `url(${state.imgUrl})`, opacity: loading === "loaded" ? "" : "0" }}></div>
        {loading === "pending" && <div className="lds-ring"><div></div><div></div><div></div><div></div></div>}
        {loading === "loaded" && <span className="copyright">"{state.card}" by {state.artist}</span>}
    </div>
}