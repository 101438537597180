import "./Rotator.css";

import { useRef, useState, useLayoutEffect } from 'react';

export default function Rotator(props: { children: React.ReactNode, degree: number }) {

    const wrapperRef = useRef<HTMLDivElement>(null);
    const [dimension, setDimension] = useState({ width: 0, height: 0 });

    useLayoutEffect(() => {
        if (wrapperRef.current) {
            const observer = new ResizeObserver(mutationRecords => {
                setDimension({
                    width: mutationRecords[0].contentRect.height,
                    height: mutationRecords[0].contentRect.width
                });
            });
            observer.observe(wrapperRef.current);
            return () => {
                observer.disconnect();
            };
        }
    }, [wrapperRef]);

    return (
        <div ref={wrapperRef} className="rotationWrapper">
            <div className="rotation" style={{ width: dimension.width + "px", height: dimension.height + "px", rotate: `${props.degree}deg` }}>
                {props.children}
            </div>
        </div>
    );
}