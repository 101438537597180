import "./ScrollContainer.css";
import { useRef, useEffect, useLayoutEffect } from 'react';
import { getGlobalRotation } from "./DOMUtils";
import { clamp, DEG2RAD } from "./Util";

const edgePadding = .2;
const stiffness = 2;

function getClampedLeft(pageWidth: number, totalWidth: number, scrollLeft: number) {
    const padding = pageWidth * edgePadding;

    const max = totalWidth - pageWidth - (2 * padding);
    const l = Math.round((scrollLeft - padding) / pageWidth) * (pageWidth);

    const left = clamp(0, max, l) + padding;

    return left;
}

function getDamping(element: HTMLElement, left: number, deltaX: number) {
    const padding = element.clientWidth * edgePadding;

    function apply(threshold: number) {
        deltaX -= threshold;
        deltaX = Math.tanh(deltaX / padding / stiffness) * padding;
        deltaX += threshold;
    }

    {
        const threshold = padding - left;

        if (deltaX < threshold) {
            apply(threshold);
        }
    }

    {
        const threshold = element.scrollWidth - element.clientWidth - padding - left;

        if (deltaX > threshold) {
            apply(threshold);
        }
    }

    return deltaX;
}

function snap(element: HTMLElement, scrollLeft: number, behavior: "smooth" | "auto" = "smooth"): void {
    const left = getClampedLeft(element.clientWidth, element.scrollWidth, scrollLeft);
    element.scroll({ left: left, top: 0, behavior: behavior });
}

type Movement = { x: number, y: number };

export default function ScrollContainer(props: { children: React.ReactNode }) {

    const scroller = useRef<HTMLDivElement>(null);

    let initialTouchX = useRef<number | undefined>(undefined);
    let initialLeft = useRef<number | undefined>(undefined);
    let initialTouchY = useRef<number | undefined>(undefined);
    let initialTop = useRef<number | undefined>(undefined);
    let rotation = useRef<number>(0);

    let deltaMovement = useRef<Movement | undefined>(undefined);
    let lastLeft = useRef<number | undefined>(undefined);
    let lastTop = useRef<number | undefined>(undefined);

    function touchEnd (element: HTMLElement) {
        let momentum = 0;
        if(deltaMovement.current !== undefined) {
            momentum = -deltaMovement.current.x;
        }
        snap(element, element.scrollLeft + momentum);
        initialTouchX.current = undefined;
        initialTouchY.current = undefined;
        deltaMovement.current = undefined;
        lastLeft.current = undefined;
        lastTop.current = undefined;
    }

    useEffect(() => {
        if (scroller.current) {
            const element = scroller.current;

            element.addEventListener("touchstart", (e: TouchEvent) => {
                initialTouchX.current = e.targetTouches[0].clientX;
                initialTouchY.current = e.targetTouches[0].clientY;
                initialLeft.current = element.scrollLeft;
                initialTop.current = element.scrollTop;
                rotation.current = getGlobalRotation(e.target as HTMLElement);
            }, { passive: false });

            let ts = -1;

            element.addEventListener("touchmove", (e: TouchEvent) => {
                if (initialTouchX.current === undefined || initialTouchY.current === undefined || initialLeft.current === undefined || initialTop.current === undefined) {
                    return;
                }

                let currentX = e.targetTouches[0].clientX;
                let currentY = e.targetTouches[0].clientY;

                let dx = initialTouchX.current - currentX;
                let dy = initialTouchY.current - currentY;

                let theta = (-rotation.current) * DEG2RAD;

                const cosTheta = Math.cos(theta);
                const sinTheta = Math.sin(theta);

                let deltaX = dx * cosTheta - dy * sinTheta;
                let deltaY = dx * sinTheta + dy * cosTheta;

                deltaX = getDamping(element, initialLeft.current, deltaX);

                if(lastLeft.current !== undefined && lastTop.current !== undefined) {

                    const dt = e.timeStamp - ts;

                    deltaMovement.current = {
                        x: dt * (lastLeft.current - (initialLeft.current + deltaX)),
                        y: dt * (lastTop.current - (initialTop.current + deltaY)),
                    }
                } 
    
                element.scrollLeft = initialLeft.current + deltaX;
                element.scrollTop = initialTop.current + deltaY;

                lastLeft.current = initialLeft.current + deltaX;
                lastTop.current = initialTop.current + deltaY;

                ts = e.timeStamp;

                e.preventDefault();
                e.stopPropagation();
            }, { passive: false });

            element.addEventListener("touchend", (e) => {
                touchEnd(element);
            }, { passive: false });

            element.addEventListener("touchcancel", (e) => {
                touchEnd(element);
            }, { passive: false });
        }
    }, [scroller]);

    useLayoutEffect(() => {
        if (scroller.current) {
            const observer = new ResizeObserver(mutationRecords => {

                if (scroller.current && initialTouchX.current === undefined && initialTouchY.current === undefined) {
                    snap(scroller.current, scroller.current.scrollLeft, "auto");
                }
            });
            observer.observe(scroller.current);
            return () => {
                observer.disconnect();
            };
        }
    }, [scroller, initialTouchX, initialTouchY]);

    const style: React.CSSProperties = {
        flex: `0 0 ${edgePadding * 100}%`
    };

    return (
        <div className="scrollContainer" ref={scroller}>
            <div className="padding" style={style}></div>
            {props.children}
            <div className="padding" style={style}></div>
        </div>
    );
}