export function getGlobalRotation (element: HTMLElement): number {
    let rot = 0;
    let parent = element;

    while (parent && parent !== document.body) {
        let style = window.getComputedStyle(parent);
        let t = style.getPropertyValue("rotate");

        if (t !== "none") {
            let value = t.substring(0, t.length-3); //get rid of the "deg" at the end
            rot += parseFloat(value);
        }

        parent = parent.parentNode as HTMLElement;
    }

    return rot;
}